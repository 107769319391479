/** api接口的统一出口 */
import userApi from './user.js'
import kbApi from './kb.js'


// 将各业务模块接口导出
export default {    
    userApi,
    kbApi,
    // ……
}