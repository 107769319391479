/**
 * 接口域名的管理
 */
const base = {
    BASEURL_DEV: {
        // SSO_BASEURL: 'http://192.168.119.30:8082',
        //线上地址
        // SSO_BASEURL: '\x68\x74\x74\x70\x3a\x2f\x2f\x31\x39\x32\x2e\x31\x36\x38\x2e\x31\x31\x39\x2e\x33\x30\x3a\x38\x30\x38\x32',
        SSO_BASEURL: '',
    },
}

// 导出开发模式下的URL配置对象
export default base.BASEURL_DEV;
