/** user业务模块接口整合 */
import base from "./base"; // 导入接口域名列表
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const SSO_BASEURL = base.SSO_BASEURL;

const userApi = {


  /**
   * 用户登录业务
   * @param {Object} params 
   */
  login(params){
    return axios.REQUEST_POST(SSO_BASEURL + "/app/api/login", params);
  },
  /**
   * 用户登录业务
   * @param {Object} params
   */
  bqtlogin(params){
    return axios.REQUEST_GET(SSO_BASEURL + "/app/api/bqtlogin", params);
  },
  getUserTotal() {
    return axios.REQUEST_GET(SSO_BASEURL + "/app/user/getUserTotal");
  },
  getPublicKey() {
    return axios.REQUEST_GET(SSO_BASEURL + "/app/getPublicKey");
  }
};

export default userApi;
