/** user业务模块接口整合 */
import base from "./base"; // 导入接口域名列表
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const SSO_BASEURL = base.SSO_BASEURL;



const kbApi = {
    /**
     * 快报单号获取
     * @returns {AxiosPromise}
     */
    getSysId(){
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getSysId")
    },
    /**
     * 下拉框数据加载
     * @returns {AxiosPromise}
     */
    getTypes(){
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getTypes")
    },
    /**
     * 保存
     * @param params
     * @returns {AxiosPromise}
     */
    saveTyxd(params){
        return axios.REQUEST_POST(SSO_BASEURL + "/app/kb/saveTyxd", params);
    },
    /**
     * 查询列表
     * @param params
     * @returns {AxiosPromise}
     */
    getList(params){
        return axios.REQUEST_POST(SSO_BASEURL + "/app/kb/getAppList", params);
    },
    /**
     * 根据id删除
     * @param params
     * @returns {AxiosPromise}
     */
    delById(params){
        return axios.REQUEST_GET(SSO_BASEURL+`/app/kb/delById/${params.id}`)
    },
    /**
     * 查询单个
     * @param params
     * @returns {AxiosPromise}
     */
    getById(params){
        return axios.REQUEST_GET(SSO_BASEURL+`/app/kb/getById/${params.id}`)
    },
    /**
     * 删除文件
     * @param params
     * @returns {AxiosPromise}
     */
    delFile(params) {
        return axios.REQUEST_POST(SSO_BASEURL+`/app/kb/delFile`,params)
    },
    /**
     * 提交
     * @param params
     * @returns {AxiosPromise}
     */
    appSubmit(params) {
        return axios.REQUEST_POST(SSO_BASEURL+`/app/kb/submit`,params)
    },
    getFactories() {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getFactories")
    },
    getCompanies(params) {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getCompanies",params)
    },
    getAwardList() {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getAwardList")
    },
    getHistoryList() {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/getHistoryList")
    },
    convert(params) {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/convert",params)
    },
    covertList(params) {
        return axios.REQUEST_POST(SSO_BASEURL + "/app/kb/covertList", params);
    },
    handleSignIn() {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/kb/handleSignIn")
    },

    signInList() {
        return axios.REQUEST_GET(SSO_BASEURL + "/app/user/signInList")
    }
};

export default kbApi;